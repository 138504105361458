.subHeading{
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

}

.grayText {
  color: var(--kss-grey-dot-color);
  font-size: 14px;
  text-align: left;

  ul{
    padding-left: 20px;
  }
}
