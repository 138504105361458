.user-profile {
  .sidebar {
    margin-top: 100px;

    .nav-pills .nav-link {
      color: #111827;
    }

    .nav-pills .nav-link.active {
      background-color: #e5e7eb;
      color: #111827;
    }
  }
}

.account-profile {
  .body-container {
    min-height: 80vh;
  }
  margin-bottom: 60px;
  padding-top: 52px;

  .warn-image {
    width: 15px;
  }

  a {
    color: var(--kss-facelift-primary-color);
  }

  .user-form-inner {
    width: 736px;
    margin: 135px auto;
    margin-bottom: 32px;
    padding: 30px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
    text-align: left;
  }

  .alert-holder {
    top: 18px;
    width: 98%;
    margin: auto;
    color: #3d434c;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    span {
      margin-left: 5px;
    }
  }

  .profile-avatar {
    position: absolute;
    top: 40px;
    left: calc(50% - 72px/2);
    width: 72px;
    height: 72px;
    border: 2px solid #dae4f2;
    border-radius: 10px;
    background: #fff4de;
    color: #3d434c;
    font-family: var(--kss-font-regular);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 65px;
    text-align: center;
  }

  .profile-name {
    position: inherit;
    top: 120px;
    color: #3d434c;
    font-family: var(--kss-font-regular);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
  }

  .single-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    .heading {
      flex: 2 1 0px;
      color: #66707f;
      font-family: var(--kss-font-regular);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }

    .value {
      flex: 4 1 0px;
      color: #3d434c;
      font-family: var(--kss-font-regular);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;

      .password-value {
        -webkit-text-security: disc;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .info-control {
      margin-bottom: 10px;

      .number {
        color: #3d434c;
        font-family: var(--kss-font-regular);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }

      .mfa-info {
        .detail {
          font-family: var(--kss-font-regular);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
        }
      }

      .add-phone-btn {
        padding: 0;
        color: var(--kss-facelift-primary-color);
        font-family: var(--kss-font-regular);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
      }

      .phone-info {
        margin-top: 10px;
        color: #3d434c;
        font-family: var(--kss-font-regular);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      .why-this {
        font-family: var(--kss-font-regular);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .edit {
      flex: 0 1 0px;

      button {
        float: right;
        overflow: hidden;
        border: none;
        background-color: Transparent;
        background-repeat: no-repeat;
        color: #66707f;
        font-size: 13px;

        &:hover {
          background: #edf1f7;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .account-profile {
    padding-top: 0;

    .user-form-inner {
      width: auto;
    }
  }
}
