.tooltipIconWrapper {
  .tooltipIconBefore {
    padding: 0;
    color: var(--kss-facelift-primary-color);
    text-align: center;
  }

  .tooltipIconAfter {
    display: none;
  }

  &:hover {
    .tooltipIconBefore {
      display: none;
    }

    .tooltipIconAfter {
      display: inline-block;
    }
  }
}


.popoverWithIcon {
  &:global(.tooltip) {
    margin-bottom: 5px;  

    :global(.arrow::before) {
      border-top-color: var(--kss-gray-500);
    }

    :global(.arrow){
      height: 0.5rem;
    }
  }

  :global(.tooltip-inner) {
    max-width: rem(262px);
    border-radius: 6px;
    opacity: 1;
    background-color: var(--kss-gray-500);
  }
}
