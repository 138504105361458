body {
  background-color: #f7f9fc !important;
}

.user-form {
  .body-container {
    min-height: 90vh;
  }
  margin-bottom: 60px;

  .warn-image {
    width: 15px;
  }

  &.phone-number-form {
    .instructions {
      text-align: left;

      p {
        font-family: var(--kss-font-regular);
        font-size: 14px;
        font-style: normal;
        line-height: 21px;
      }

      li {
        font-family: var(--kss-font-regular);
        font-size: 14px;
        font-style: normal;
        line-height: 21px;
      }
    }

    .align-code {
      position: absolute;
      margin-top: 8px;
      margin-left: -175px;
      font-size: 14px;
    }
  }

  &.set-password-form {
    .user-form-inner {
      padding-top: 18px;

      .reset-btn {
        min-width: 148px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }

  &.reset-from {
    .user-form-inner {
      padding-top: 18px;

      .head {
        margin-bottom: 30px;

        h2 {
          margin-bottom: 20px;
        }

        p {
          padding: 0;
          color: #3d434c;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
        }
      }

      .form-group {
        margin-bottom: 40px;
      }

      .reset-btn {
        min-width: 200px;
        margin-top: 10px;
        margin-bottom: 10px !important;
      }
    }
  }
  background: url(../assets/images/bg-loginpages.png) no-repeat -94px 190px;
  background-position-x: -180px;

  .logoform {
    display: block;
    height: 30px;
    margin: 40px auto;
    overflow: hidden;
    background: url(../assets/images/logo.svg) no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    background-position-x: center;
  }

  .user-form-inner {
    width: 440px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
    text-align: center;

    .head {
      margin-bottom: 20px;

      h2 {
        margin: 0;
        color: #3d434c;
        font-family: var(--kss-font-regular);
        font-size: 21px;
        font-style: bold;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
      }

      p {
        margin: 0 0 12px;
        color: #979797;
        font-family: var(--kss-font-regular);
        font-size: 21px;
        font-weight: 400;
        letter-spacing: -0.14px;
        line-height: 30px;
        text-align: center;
      }

      .text {
        margin: 25px auto;
        padding: 0;
        color: #3d434c;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
      }
    }

    .form-group {
      margin-bottom: 20px;
    }

    .form-control {
      box-sizing: border-box;
      height: 38px;
      padding: 10px 11px;
      border: 1px solid #dae4f2;
      border-radius: 5px;
      background: #f7f9fc;
      color: #979797;
      font-family: var(--kss-font-regular);
      font-size: 14px;
      line-height: 16px;

      &.select {
        padding: 7px 11px;
        color: #000;
      }

      &.phone-input {
        padding: 10px 60px;
        color: #000;
      }

      &::-webkit-input-placeholder {
        /* Edge */
        color: #979797;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #979797;
      }

      &::placeholder {
        color: #979797;
      }

      &.no-border-right {
        border-right: none;
      }

      &.no-box-shadow {
        box-shadow: none;
      }
    }

    label {
      display: flex;
      align-items: center;
      margin: 0 0 3px;
      color: #66707f;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }

    .user-from-caption {
      display: block;
      margin: 40px 0 20px;
      color: #3d434c;
      font-family: var(--kss-font-regular);
      font-size: 14px;
      line-height: 21px;
      text-align: center;

      span {
        display: block;
      }

      a {
        color: var(--kss-facelift-link-color);
        font-weight: 600;
      }

      p {
        margin: 0;
      }
    }

    .form-check-input {
      margin-top: -2.5px;
      margin-left: -1.25rem;
    }

    .check-box {
      display: 'flex';
      margin-left: '20px';
      color: var(--kss-facelift-primary-color);
    }

    .submit-btn {
      display: inline-block;
      min-width: 120px;
      max-height: 36px;
      padding: 8px 20px;
      border-radius: 5px;
      background: #005ce6;
      color: #ffffff;
      font-family: var(--kss-font-regular);
      font-size: 14px;
      font-style: bold;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
    }

    .login-caption {
      margin: 23px auto 0;
      color: #66707f;
      font-family: var(--kss-font-regular);
      font-size: 14px;
      font-style: bold;
      line-height: 19px;
      text-align: center;

      a,
      button {
        color: var(--kss-facelift-link-color);
        font-weight: 600;
      }

      p {
        margin: 0;
      }
    }

    .info-text {
      margin-top: 5px;
      margin-bottom: 40px;
      color: #66707f;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;

      button {
        padding: 0;
        color: #005ce6;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
      }
    }

    .password-policy {
      margin-top: 5px;
      color: #66707f;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    .get-email-btn {
      padding: 0 0 2px;
      font-size: 14px;
    }
  }
}

.login-form-background-image {
  background: url(../assets/images/White.svg) !important;
}

.user-form.newbg {
  background: url(../assets/images/White.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.back-link {
  display: flex;
  margin-bottom: 25px;
  margin-left: -12px;
  border: none;
  background: none;
  color: var(--kss-facelift-primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.checkinbox {
  .user-form-inner {
    padding: 92px 32px 70px;

    .email-icon-holder {
      margin-bottom: 40px;
    }
  }
}

.alert-info-holder {
  top: 39px;
  right: 0;
  left: 0;
  width: 96%;
  margin: 0 auto;
  color: #3d434c;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;

  span {
    margin-left: 5px;
  }
}

.h-90vh {
  height: 90vh;
}
@media (max-width: 767px) {
  .user-form {
    .body-container {
      min-height: 80vh;
    }
    background: none;

    .user-form-inner {
      width: 100%;
    }
  }
}
