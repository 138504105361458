.DeleteAccount{
  .Card {
    width: 440px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
    text-align: center;
  }

  .sadFace{
    color: var(--kss-grey-dot-color);
    font-size: 48px;
  }

  .Heading {
    color: #4b5563;
    font-family: var(--kss-font-regular);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
}

.noBackground{
  .DeleteAccount{
    background: none;
  }
}
