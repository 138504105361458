.kssBtn {
  display: inline-block;
  padding: 8px 20px;
  border: transparent;
  border-radius: 5px;
  color: #ffffff;
  font-family: var(--kss-font-regular);
  font-size: 14px;
  font-style: bold;
  font-weight: 600;
  line-height: 19px;
  text-align: center;

  &:global(.kss-btn-primary) {
    border: 1px solid var(--kss-facelift-primary-color);
    background-color: var(--kss-facelift-primary-color);
    box-shadow: 0 2px 4px rgba(147, 157, 177, 0.3);
  }

  &:global(.kss-btn-outline-danger){
    border: 1px solid #dc3545;
    background-color: transparent;
    box-shadow: none;
    color: #dc3545;
  }



  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }


}
