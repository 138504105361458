.container {
  width: 440px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
  color: var(--gray-700, #374151);
  font-family: var(--kss-font-regular);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.resendButtonText {
  margin-top: 26px;
  margin-bottom: 0%;
  color: var(--gray-700, #374151);
  font-family: var(--kss-font-regular);
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;

  .resendButton {
    padding: 0;
    border: none;
    background-color: #ffffff;
    color: var(--light-blue-800, #075985);
    font-weight: 600;

    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.disabled {
  color: #6b7280 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.contactUsText {
  color: var(--gray-500, #6b7280);
  font-family: var(--kss-font-regular);
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;

  .supportEmail {
    color: #17648d;
  }
}
