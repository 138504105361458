.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1275px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 40px;
  color: #66707f;
  font-family: var(--kss-font-regular);
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.footerLink {
  display: flex;
  margin: 0;
  padding: 0 0 0 30px;
  font-family: var(--kss-font-regular);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  list-style: none;
}

.footerText {
  margin: 0;
}


.footerListItem {
  display: table-cell;
  padding-right: 20px;
  vertical-align: middle;

  &:last-child {
    padding-right: 0;
  }
}

.footerLink {
  padding: 0;
  color: var(--kss-facelift-primary-color);

  &:hover {
    opacity: 0.7;
  }
}

.footerDot {
  display: table-cell;
  padding-top: 2px;
  color: var(--kss-grey-dot-color);
  font-size: 5px;
}

@media (max-width:767px ) {
  .footer {
    flex-direction: column-reverse;
  }

  .footerLink {
    margin-bottom: 20px;
  }

  .footerListItem {
    padding-right: 0;
  }
}
