@import 'stylesheets/font';

:root {
  --kss-font-regular: 'Noto sans', sans-serif;
  --kss-facelift-primary-color: #1b5c84;
  --kss-facelift-link-color: #48a1d8;
  --kss-primary-color: #716aca;
  --kss-blue-color: #005ce6;
  --kss-blue-color-hover: #0047b2;
  --kss-red-color: #dc2626;
  --kss-white-color: #ffffff;
  --kss-grey-background-color: #f9fafb;
  --kss-grey-text-color: #979797;
  --kss-grey-dot-color: #6b7280;
  --kss-black-color: #3d434c;
  --kss-lightgreen-color: #ecfdf5;
  --kss-green-text-color: #065f46;
  --kss-red-color: #dc2626;
  --kss-gray-500: #6b7280;
  --kss-yellow-050: #fffbeb;
  --kss-orange-color: #db6539;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
