#root {
  background-color: #f7f9fc;
}

.btn:focus {
  box-shadow: none !important;
}

.auth-container {
  min-height: 78vh;
  margin-top: 100px;
}

.phone-num-validate {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.code-num-validate {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.login-details-validate {
  margin-top: 5px;
  font-family: var(--kss-font-regular);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.icon-show-holder{
  position: relative;

  .show-link {
    display: flex;
    align-items: center;
    border: 1px solid #dae4f2;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #f7f9fc;
    color: var(--kss-facelift-primary-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: right;
    text-decoration: none;
    cursor: pointer;

    &:hover{
      opacity: 0.7;
    }
  }
}

.link-expired-container {
  width: 440px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  background: var(--kss-white-color);
  box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
  text-align: center;

  .sad-face-image {
    width: 48px;
    height: 48px;
  }

  .link-expired-heading {
    margin-top: 40px;
    color: var(--kss-red-color);
    font-size: 20px;
  }

  .link-expired-text {
    font-size: 14px;
  }
}

.Toastify__toast-container {
  .Toastify__toast-theme--light{
    border-radius: 15px;
  }

  .Toastify__toast-icon{
    margin-inline-end: 14px;
  }

  .Toastify__toast--error{
    .Toastify__close-button.Toastify__close-button--light{
      margin: auto;
      margin-right: 16px;
    }

    .Toastify__close-button > svg{
      width: 20px;
      height: 20px;
      fill: #ff0000;
    }
  }

  .Toastify__toast-theme--light.Toastify__toast--success {
    padding-left: 18px;
    background-color: var(--kss-lightgreen-color);
    color: var(--kss-green-text-color);
  }

  .Toastify__toast--success{
    .Toastify__close-button.Toastify__close-button--light{
      width: 18px;
      margin: auto;
      margin-right: 18px;
      opacity: 1;
      color: #10b981;
    }

    .Toastify__close-button > svg{
      width: 20px;
      height: 20px;
    }
    
  }


  .Toastify__toast-theme--light.Toastify__toast--warning {
    padding-left: 18px;
    background-color: var(--kss-yellow-050);
  }

  .Toastify__toast--warning{
    .Toastify__close-button.Toastify__close-button--light{
      width: 18px;
      margin: auto;
      margin-right: 18px;
      opacity: 1;
      color: var(--kss-gray-500);
    }

    .Toastify__close-button > svg{
      width: 16px;
      height: 16px;
    }
    
  }
}
