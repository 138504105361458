.Sidebar {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 250px;
  margin-top: 92px;
  text-align: center;
  vertical-align: top;
}

.layoutContainer{
  max-width: 1275px;
  margin: auto;
}
  
.SidebarButton {
  display: block;
  width: 90%;
  height: '39px';
  margin-bottom: 10px;
  margin-left: auto;
  padding: 7px;
  border: none;
  border-radius: 6px;
  background-color: #f7f9fc;
  color: #000000;
  text-align: left;
  
  &:hover,
  &:focus,
  &:active {
    background-color: #e5e7eb;
    color: #000000;
    text-decoration: none;
  }
  
  &.active {
    background-color: #e5e7eb;
  }
}
  
.OrganizationsButton {
  &:active,
  &:focus {
    border: 0;
    background-color: transparent !important;
  }
}
  
.sidebarIcon{
  margin-right: 10px;
  color: var(--kss-grey-text-color)
}
  
.accordionFontsize{
  font-size: 16px;
}
  
.organizations{
  height: 39.79px;
  margin-left: 25px;
}
  
.organizationIcon{
  width: 16px;
  height: 20px;
  
}

.organizationTextWrap{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  
@media (max-width: 767px) {
  .Sidebar {
    position: relative;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  
    .SidebarButton {
      width: 100%;
    }
  }
  
  .organizations{
    margin-left: 0;
  }
}
