.organizationName {
  position: inherit;
  top: 31px;
  color: #3d434c;
  font-family: var(--kss-font-regular);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}
  
.titleRow {
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  text-align: center;
}
  
.usersTitle {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.permissionsTextColor {
  color: var(--kss-grey-dot-color);
}

.cancelBtn{
  height: 38px;
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  border-radius: 0.25rem;
  background-color: #edf1f7;
  color: #66707f;
}

.cancelBtn:hover{
  background-color: #d2d9e3;
}
  
.inviteBtn {
  position: absolute;
  right: 20px;
}
  
.invited {
  color: var(--kss-grey-text-color);
  font-style: italic;
}
