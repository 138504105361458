.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: var(--kss-font-regular);
  
  a[disabled] {
    pointer-events: none;
  }

  :global(.cancelbutton) {
    margin-top: 1.5rem;
    border-radius: 6px
  }
}
  
.Footer {
  margin-bottom: 60px;
}


.helpTooltip {
  width: 376px;

  :global(.tooltip-inner) {
    max-width: 100%;
  }
}

