#header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 15px 0 0;
  background: #fff;
  box-shadow: 0 0 5px #0000001a;

  .nav-holder {
    display: flex;
    align-items: center;

    .logo-area {
      -ms-flex: 0 0 6.5%;
      flex: 0 0 6.5%;
      max-width: 6.5%;
      height: 23px;
      border-right: 1px solid #bfc9d5;
    }
  }

  .basic-navbar-nav {
    display: flex;
    align-items: center;
    padding: 0 23px;
    color: #001f3b;
  }

  .navbar-nav {
    position: relative;
    margin-right: auto;
    padding-left: 25px;
  }

  .navbar {
    padding: 0;
  }

  .navbar-nav {
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 1px;
      height: 27px;
      background: #c8d0da;
    }

    a {
      margin-right: 40px;
      padding: 14px 0 31px;
      color: #66707f;
      font-family: var(--kss-font-regular);;
      font-size: 14px;
      font-weight: 600;
      line-height: 4px;
      text-decoration: none !important;
    }
  }

  .nav-holder .logo-area .logo {
    background: url(../assets/images/kudo-logo.svg) no-repeat;
    color: #001f3b;
    font-family: 'Avenir Next LT Pro';
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    text-decoration: none;
  }

  .navbar-brand {
    width: 8rem;
    height: 27px;
    padding: 30px 0 15px 35px;
    overflow: hidden;
    background: url(../assets/images/kudo-logo.svg) no-repeat;
    text-indent: 100%;
    white-space: nowrap;
  }

  .nav-holder .logo-area .logo span {
    display: block;
  }

  .name-initials {
    width: 36px;
    height: 36px;
    margin-top: -17px;
    border-radius: 5px;
    background: #fff4de;
    color: #3d434c;
    font-family: var(--kss-font-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
  }

  .user-name {
    margin-top: -18px;
    margin-right: 15px;
    color: #3d434c;
    font-family: var(--kss-font-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: end;
  }

  .globe {
    margin-top: -20px;
    margin-right: 26px;
  }
}

@media (max-width: 767px) {
  #header {
    .globe {
      display: none;
    }

    .user-name {
      display: none;
    }
  }
}
