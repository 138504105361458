.ModalWidth {
  max-width: 440px;
}

.Heading {
  width: 450px;
  margin: 0 auto;
  color: var(--kss-red-color);
  font-family: var(--kss-font-regular);
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.sadFace{
  display: block;
  color: var(--kss-red-color);
  font-size: 48px;
  text-align: center;
}

.grayText {
  color: var(--kss-grey-dot-color);
  font-size: 14px;
  text-align: left;
}

.btnContainer{
  width: 450px;
  margin: 0 auto;
}

.btnWidth{
  width: 100%;
}

.email{
  width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
