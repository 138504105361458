.modalContainer {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 35%;
  height: 70%;
  padding: 40px;
  transform: translate(-50%, -50%);
  border: 1px solid #ffffff;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  text-align: center;
}

.modalHeading {
  color: var(--kss-facelift-primary-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.scrollBox {
  max-width: 500px;
  height: 70%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 48px 30px 13px;
  overflow-y: scroll;
  border: 1px solid #9ca3af;
  border-radius: 20px;
  text-align: left;

  p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}


.btnContainer{
  width: 65%;
  margin: auto;
}

@media (max-width: 768px){
  .modalContainer {
    width: 100%;
    transform: translate(-50%, -40%);

  }

  .scrollBox {
    width: 100%;

  }
}


