.table {
  width: 736px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background: var(--kss-white-color);
  box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
  font-family: var(--kss-font-regular);
  font-size: 14px;
  text-align: left;
}
  
.head {
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--kss-grey-background-color);
  color: #6b7280;
  vertical-align: middle;
}
  
.row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
  
.cell {
  padding: 12px;
  vertical-align: middle;
}
