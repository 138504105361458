.kudoText {
  display: block;
  margin-top: 24px;
  margin-bottom: 12px;
  color: var(--kss-grey-text-color);
  font-family: var(--kss-font-regular);
}

.defaultEmail{
  color: var(--kss-black-color);
  font-weight: 700;
}

.textContainer{
  margin: auto;
}

.textbox{
  max-width: 400px;
  margin-left: 20px;
}

.heading{
  margin-bottom: 24px;
  color: var(--kss-facelift-primary-color);
  font-size: 20px;
  font-weight: 600;
}

.subheading {
  color: var(--kss-orange-color);
  font-size: 14px;
  font-weight: 600;
}

.textboxHeading{
  font-size: 30px;
  font-weight: 600;
}

.item{
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;

  .checkIcon {
    margin-right: 12px;
    color: #f5c451;
    font-size: 26px;
  }
}

.marginTop{
  margin-top: 10%;
}

.disable{
  pointer-events: none;
}
