@font-face {
  font-family: 'Noto sans Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Noto sans Bold'),
    url(../assets/fonts/NotoSans-Bold.ttf) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Noto sans BoldItalic';
  font-style: italic;
  font-weight: 700;
  src: local('Noto sans BoldItalic'),
    url(../assets/fonts/NotoSans-BoldItalic.ttf) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Noto sans Italic';
  font-style: italic;
  font-weight: 400;
  src: local('Noto sans Italic'),
    url(../assets/fonts/NotoSans-Italic.ttf) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Noto sans';
  font-style: normal;
  font-weight: 400;
  src: local('Noto sans'),
    url(../assets/fonts/NotoSans-Regular.ttf) format('woff2');
  font-display: swap;
}