.container {
  margin-top: 120px;
}

.title {
  margin-bottom: 12px;
  font-family: var(--kss-font-regular);
  font-weight: 700;
  text-align: center;
}
