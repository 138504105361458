#modal {
  font-family: Noto Sans, sans-serif;

  .form-group {
    .form-label {
      display: flex;
      align-items: center;
      color: #66707f;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }

    input,
    select {
      box-sizing: border-box;
      border: 1px solid #dae4f2;
      border-radius: 5px;
      background: #f7f9fc;

      &.no-border-right {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.no-box-shadow {
        box-shadow: none;
      }
    }
  }

  .modal-content {
    border-radius: 12px;
    border-color: #0000001a;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 0 5px #0000001a;
  }

  .btn-submit {
    width: 100px;
    height: 36px;
    border-radius: 5px;
    background: #005ce6;
    color: #ffffff;
    font-family: var(--kss-font-regular);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .btn-submit:active,
  .btn-submit:hover,
  .btn-submit:focus {
    outline: none;
    background: #0047b2;
    box-shadow: none;
    color: #fff;
  }

  .p-t-3 {
    padding-top: 3rem;
  }

  .p-b-7 {
    padding-bottom: 7rem;
  }

  .btn-delete {
    height: 40px;
    padding: 12px 18px;
    border: solid 1px #fff;
    border-radius: 10px;
    background: #db3043;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  .btn-long {
    width: 200px !important;
  }

  .btn-delete:active,
  .btn-delete:hover,
  .btn-delete:focus {
    outline: none;
    background: #a82533;
    box-shadow: none;
    color: #fff;
  }

  .btn-cancel {
    height: 40px;
    padding: 12px 18px;
    border: solid 1px #fff;
    border-radius: 10px;
    background: #edf1f7;
    color: #66707f;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  .btn-long {
    width: 200px !important;
  }

  .btn-cancel:active,
  .btn-cancel:hover,
  .btn-cancel:focus {
    outline: none;
    background: #e5e5e5;
    box-shadow: none;
    color: #3d434c;
  }

  .small-light-text {
    color: #3d434c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .alerting-text {
    color: #bf5700;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .did-not-get-code {
    color: #66707f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .resend-btn {
    color: var(--kss-facelift-link-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }

  .invite-user-btn {
    min-width: 106px;
  }
}

.email-update-modal {
  .form-group {
    margin-bottom: 0.3rem;
  }

  .danger-text {
    color: #ed0019;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
}

.invite-user-modal {
  #modal {
    max-width: 430px;
  }
}
