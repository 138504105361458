.DeleteAccount {
  background: none;

  .Container {
    min-height: 90vh;
  }
  
  .Heading {
    color: #4b5563;
    font-family: var(--kss-font-regular);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
}

.noBackground{
  .DeleteAccount{
    background: none;
  }
}

.skeleton{
  text-align: center;
}
