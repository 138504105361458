.deleteIconContainer{
  display: flex;
  align-items: center;
  min-width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 24px;
  background-color: #fee2e2;
}

.deleteIcon{
  margin: auto;
  color: var(--kss-red-color);
}

.grayText {
  color: var(--kss-grey-dot-color);
  font-size: 14px;
  text-align: left;

  ul{
    padding-left: 20px;
  }
}

.Heading {
  color: #4b5563;
  font-family: var(--kss-font-regular);
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.btnContainer{
  display: flex;
  justify-content: space-around;

  :global(.btnSize) {
    width: 150px;
    height: 38px;
  }

  :global(.btnColor) {
    background-color: var(--kss-red-color);

    &:hover{
      background-color: #dc262631;
    }
  }

  :global(.cancelBtn){
    color: var(--kss-white-color);
  }
}

.containerFlex{
  display: flex;
}
