.AccountDeactivated {
  background: url(../assets/images/bg-loginpages.png) no-repeat -180px 190px;

  .Logo {
    display: block;
    height: 30px;
    margin: 40px auto;
    overflow: hidden;
    background: url(../assets/images/logo.svg) no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    background-position-x: center;
  }

  .Container {
    min-height: 90vh;
  }

  .Card {
    width: 440px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(102, 112, 127, 0.1);
    text-align: center;
  }

  .SadFace {
    width: 48px;
    height: 48px;
    margin-bottom: 30px;
  }

  .Heading {
    color: var(--kss-red-color);
    font-family: var(--kss-font-regular);
    font-size: 20px;
    font-weight: 700;
  }

  .Text {
    font-family: var(--kss-font-regular);
    font-size: 14px;
  }

  .Email {
    font-weight: 600;
  }
}
