.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  color: #66707f;
  font-family: var(--kss-font-regular);
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  .footer-link {
    display: flex;
    margin: 0;
    padding: 0 0 0 30px;
    font-family: var(--kss-font-regular);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    list-style: none;

    li {
      padding-right: 30px;

      a {
        color: #005ce6;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  p {
    margin: 0;
  }

  .en-dropdown {
    margin-left: 20px;

    img {
      width: 22px;
      height: 22px;
      fill: #716aca;
      vertical-align: bottom;
    }

    .select-local-lang {
      padding-left: 5px;
    }

    select {
      width: 45px;
      border: none;
      outline: none;
      background: transparent;
      color: #716aca;
    }
  }
}

@media (max-width:767px ) {
  .footer {
    flex-direction: column-reverse;

    .footer-link {
      margin-bottom: 20px;

      li {
        padding-right: 0;
      }
    }
  }
}
