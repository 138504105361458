.formWidth{
  max-width: 500px;
}

.inputButton{
  min-width: 126px;
}

.alignButtons{
  text-align: end;
}

.errorText{
  color: var(--kss-red-color);
  font-size: 20px;
}
