@import 'stylesheets/variables';

.Container {
  button:after {
    display: none;
  }

  button:focus {
    box-shadow: none;
  }

  button:hover {
    color: $kps-grey-color;
  }

  .dropdown-item {
    font-size: 12px;
  }
}
